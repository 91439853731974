.thank-you-page {
    text-align: center;
    margin-top: 50px;
    padding:150px 0px !important;
  }
  
  .thank-you-page h1 {
    color: #1E9BCC; /* Green color */
    font-size: 36px;
  }
  
  .thank-you-page h2 {
    color: #F5E399; /* Grey color */
    font-size: 24px;
  }
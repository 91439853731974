.form-contro {
    display: block;
    width: 100% !important;
    height: 34px !important;
    padding: 6px 12px !important;
    font-size: 14px !important;
    line-height: 1.42857143;
    margin-top: 0px !important;
    color: #555 !important;
    background-color: #fff;
    line-height: 0px !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075) !important;
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
}

.form-control {
    margin: 0px !important;
    padding: 6px 12px !important;
    color: #555;
    background-color: #fff !important;
    background-image: none;
    border: 1px solid #ccc !important;
}

.appointment-form form textarea {
    min-height: 54px !important;
    /* Increased height for better interaction */
    overflow: auto !important;
   line-height: 1.42857143 !important;
    /* Allow vertical resizing */
}

label {
    font-size: 14px !important;
    display: inline !important;
    color: black !important;
    font-weight: bold;
    margin-bottom: 5px !important;
    font-family: Ubuntu, Helvetica, Arial, sans-serif !important;
}

.form-group {
    margin-bottom: 15px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.submit-bt {
    width: 100%;
    color: #ffffff !important;
    background-color: #337ab7 !important;
    border-color: #ffffff !important;
    font-size: 1.75em !important;
    font-weight: bold !important;
    padding: 6px 12px;
    border-radius: 4px;
    margin-top: 30px;
}
.blue-btn{
    margin-left: 15px !important;
    margin-right: 15px !important;
}
.form-control:focus{
    border: 1px solid #ccc !important;
    box-shadow: none !important;
}
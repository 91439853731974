/*
Template: Donto - React Creative Dentist and Medical Template
Author: CreandyStudio
Author URI: https://creandystudio.com/donto-react
Description: Dentist and Medical React Template
Version: 1.0
*/

/*
=================================
|***    Table of contents:   ***|
=================================

1. General styles
2. Typography
3. Helpers
4. Preloader
5. Go up button
6. Header and navigation
7. Welcome Hero Section
8. About us
9. Service
10. Fun facts
11. Testimonial
12. Blog
13. Contact
14. Single Blog
15. Footer
16. Shape
17. Home Page 2
18. Price
19. Blog 2 
20. Newsletter
21. Error 404 Page
22. Etc CSS

*/


/* ----------------------------------------
   ----------- 1.GENERAL STYLES -----------
   ---------------------------------------- */


/* Google font - Defualt Fonts*/

@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300,300i,400,400i,600,600i,700,700i|Roboto+Slab:300,400,700&display=swap');

/* Google Font for Home Page Two */

@import url('https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i|Poppins:300,400,500,600,700,800,900&display=swap');

/* change here, full html color,size,background will same as here */

@font-face {
    font-family: "Catamaran-Regular";
    src: url('../assets/fonts/Catamaran-Regular.eot');
    src: url('../assets/fonts/Catamaran-Regular.eot') format('embedded-opentype'),
         url('../assets/fonts/Catamaran-Regular.woff2') format('woff2'),
         url('../assets/fonts/Catamaran-Regular.woff') format('woff'),
         url('../assets/fonts/Catamaran-Regular.ttf') format('truetype');
  }

:root {
    --main-color: #fc5185;
    --second-color: #004caa;
    --heading-color: #1E9BCC;
    --body-color: #666;
    --section-bg: #004caa;
    --box-shadow: 8.135px 18.271px 40px 0px rgba(0, 0, 0, 0.08);
    --heading-family: 'Catamaran-Regular';
    /*--body-family: 'Roboto Slab', serif;*/
    --body-family: 'Catamaran-Regular';
    --main-color-two: #FF8A8B;
    --second-color-two: #1E266D;
    --heading-color-two: #222222;
    --body-color-two: #666666;
    --section-bg-two: #1E266D;
    --box-shadow-two: 10px 15px 40px rgba(0, 0, 0, 0.06);
    --heading-family-two: 'Poppins', sans-serif;
    --body-family-two: 'Lora', serif;
}

* {
    margin: 0px;
    padding: 0px;
}

body,
html,
.wrapper {
    height: 100%;
}

body {
    font-family: var(--body-family) !important;
    color: var(--body-color) !important;
    font-size: 16px !important;
    line-height: 30px !important;
    font-weight: 400 !important;
    overflow-x: hidden !important;
}
body.style-two {
    font-family: var(--body-family-two) !important;
    color: var(--body-color-two) !important;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    overflow-x: hidden;
}

a:focus {
    outline: 0 solid
}

a {
    cursor: pointer;
}
img {
    max-width: 100%;
    height: auto;
}

input::-moz-focus-inner {
    border: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

ul {
    padding: 0;
    margin: 0 !important;
    list-style-type: none;
}

a:hover {
    text-decoration: none;
    outline: none !important;
}

a:focus,
a:active {
    text-decoration: none;
    outline: none !important;
}

::-webkit-input-placeholder {
    color: #666;
    opacity: 1;
    /* Firefox */
}

::-moz-placeholder {
    color: #666;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    color: #666;
    opacity: 1;
    /* Firefox */
}

::-ms-input-placeholder {
    color: #666;
    opacity: 1;
    /* Firefox */
}

::placeholder {
    color: #666;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #666;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #666;
}


/* section padding */

.section-padding {
    padding: 60px 0px;
}

.section-bg {
    background: var(--second-color);
}

.section-bg-two {
    background: var(--second-color-two);
}

.section-title-one {
    margin-bottom: 27px;
}

.section-title-one h1 {
    line-height: 1;
}

.theme-bg {
    background: var(--main-color);
}

.theme-gradient {
    background: var(--main-color);
    background: -webkit-gradient(linear, right top, left top, from(var(--main-color)), to(var(--main-color)));
    background: -o-linear-gradient(right, var(--main-color), var(--main-color));
    background: linear-gradient(to left, var(--main-color), var(--main-color));
}

.theme-btn:hover {
    background: #fff;
    color: #000 !important;
    border-color: #fff;
}
.theme-btn {
    display: inline-block;
    border-width: 1px;
    border-color: var(--main-color);
    border-style: solid;
    height: 61px;
    text-align: center;
    line-height: 55px !important;
    padding: 0px 45px;
    background: var(--main-color);
    color: #fff;
    text-transform: capitalize;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    position: relative;
    z-index: 2;
    border-radius: 3px;
}
.theme-btn.btn_black {
    border-color: #1E9BCC;
    color: #1E9BCC;
    background: transparent;
    transition: .4s;
}
.theme-btn.btn_black:hover {
    border-color: #1E9BCC;
    color: #fff !important;
    background: #1E9BCC;
}
.theme-btn.fill-btn {
    margin-right: 20px;
}


.text-white .btn_black:hover {
    color: #fff !important;
}

.btn-link {
    color: var(--main-color);
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    font-weight: 700;
    padding-bottom: 4px;
    border-bottom: 2px solid var(--main-color);
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    display: inline-block;
}

.btn-link:hover {
    text-decoration: none;
}


/* ----------------------------------------
   -------------- 2.TYPOGRAPHY ------------
   ---------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    font-weight: 600 !important;
    color: var(--heading-color);
    font-family: var(--heading-family);
}

h1 {
    font-size: 46px;
    line-height: 60px;
}

h2 {
    font-size: 36px;
    line-height: 48px;
}

h3 {
    font-size: 24px;
    line-height: 36px;
}

h4 {
    font-size: 20px;
    line-height: 30px;
}

h5 {
    font-size: 18px;
    line-height: 26px;
}

h6 {
    font-size: 14px;
    line-height: 24px;
}

p {
    margin-bottom: 0px;
}

a {
    text-decoration: none;
    font-weight: 700;
    outline: none !important;
    cursor: pointer;
}


/* Style Two TYPOGRAPHY 
------------------------------*/

body.style-two h1,
body.style-two h2,
body.style-two h3,
body.style-two h4,
body.style-two h5,
body.style-two h6 {
    font-family: var(--heading-family-two);
    margin: 0px;
    font-weight: 600;
    color: var(--heading-color-two);
}

body.style-two>a {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    font-size: var(--heading-family-two) !important;
}


/* preloader */

.pre-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #9AE7FC;
    z-index: 9999999999999999999;
    left: 0;
    top: 0;
}

.loader {
    width: 300px;
    height: 250px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0.0)
    }
    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}


/* scrollup */

#scrollUp {
    background-color: var(--main-color);
    border-radius: 50%;
    bottom: 20px;
    color: #ffffff;
    font-size: 22px;
    height: 50px;
    line-height: 50px;
    right: 20px;
    text-align: center;
    width: 50px;
    -webkit-box-shadow: 0 10px 20px 0 rgba(23, 22, 49, 0.1);
    box-shadow: 0 10px 20px 0 rgba(23, 22, 49, 0.1);
    font-weight: 400;
}


/* header */
.main-menu {
    position: relative;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
}

.main-menu nav li a {
    color: #315D9C;
    text-transform: capitalize;
    padding-left: 40px;
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
}

.main-menu ul>li a:hover {
    color: #000;
}

.main-menu nav li a.activeMenu{
    color: #000;
}

.main-menu ul li ul li:hover a {
    background: #fff;
    color: #000066;
}

.menu-style-one ul li ul {
    position: absolute;
    width: 190px;
    text-align: center;
    background: #315D9C;
    z-index: 9999;
    left: 0;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    -webkit-transform: translateY(25px);
        -ms-transform: translateY(25px);
            transform: translateY(25px);
    -webkit-box-shadow: 0px 13px 35px -12px rgba(35, 35, 35, 0.15);
            box-shadow: 0px 13px 35px -12px rgba(35, 35, 35, 0.15);
}

.menu-style-one ul li {
    display: inline-block;
    position: relative;
}

.menu-style-one ul li ul li {
    display: block;
}

.menu-style-one ul li ul li a {
    padding-left: 0px;
    display: block;
    color: #fff;
    padding: 4px 0px;
}

.menu-style-one ul li:hover ul {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0px);
        -ms-transform: translateY(0px);
            transform: translateY(0px);
}

.navbar.navbar-expand-md {
    padding: 0;
}

.sticky {
    top: 0;
    width: 100%;
    position: fixed !important;
    padding: 0px;
    background: #fff;
    animation: sticky .9s ease-in-out;
    box-shadow: 0 13px 35px -12px rgba(35,35,35,.15);
}
.header-one.sticky {
    transform: translateY(0px);
}
.header-two.sticky {
    padding: 5px 0px;
}

div.promo-text p.vNoMarginBottom{
    margin-bottom: 0 !important;
}

@media screen and (max-width: 767px) {
    .vAwards li{
        display: inline-block;
        margin: 0 10px 20px 0;
    }
    .vAwards li img{
        width: 50px;
    }
    .main-menu nav li a.activeMenu{
        color: #000066;
    }
    .vAboutDoc{
        text-align: center;
    }
    .vServiceImg{
        text-align: center;
    }
}

.header-one {
    position: absolute;
    width: 100%;
    z-index: 99999;
    transform: translateY(50px);
}

.main-menu .container {
    background: #fff;
    border-radius: 10px;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    padding: 0px 30px;
}

.main-menu nav li a {
    padding-top: 25px;
    padding-bottom: 25px;
    display: inline-block;
    transition: .4s;
}


/* main menu two - 2 style */

.main-menu-two .menu-items li {
    display: inline-block;
    position: relative;
    margin-right: 40px;
}

.main-menu-two .menu-items li .submenu {
    position: absolute;
    left: 0;
    background: #FF8A8B;
    min-width: 200px;
    top: 0;
    -webkit-transform: translateY(100px);
        -ms-transform: translateY(100px);
            transform: translateY(100px);
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    opacity: 0;
    visibility: hidden;
    z-index: 99999;
}

.main-menu-two .menu-items li a {
    text-transform: capitalize;
    padding: 20px 0px;
    font-size: 18px;
    display: inline-block;
    color: #fff;
    font-weight: 400;
    position: relative;
    transition: .4s;
}

.header-two {
    padding: 31px 0px;
    background: var(--second-color-two);
    display: block;
    width: 100%;
    z-index: 9999;
    position: relative;
    transition: .5s;
}

.home-slider-two p {
    color: #555;
}
.main-menu-two .menu-items li:last-child {
    margin-right: 0px;
}
.menu-items li .submenu li {
    margin-right: 0;
    display: block;
    text-align: center;
}
.main-menu-two .menu-items li .submenu li a {
    padding: 8px;
    border: none;
    border-bottom: 1px solid #fff3f496;
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;

    transition: .4s;
}

.main-menu-two .menu-items li:hover .submenu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(70px);
        -ms-transform: translateY(70px);
            transform: translateY(70px);
            z-index: 999999;
}

.main-menu-two .menu-items li .submenu li:last-child a {
    border-bottom: 0;
}

.main-menu-two .menu-items>li>a::before {
    position: absolute;
    left: 0;
    width: 0;
    height: 2px;
    content: "";
    background: var(--main-color);
    top: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.main-menu-two .menu-items>li:hover a::before {
    width: 100%;
}



/* hero slider home one */

.single-hero-slide {
    /*background: var(--second-color);*/
    background: url('../assets/img/whatsinaname/HomePageBanner.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    padding-top: 340px;
    padding-bottom: 220px;
    position: relative;
}

@media (min-width: 991px) {
    .hero-slide-left {
        padding-right: 60px;
    }
}

.hero-slide-left p {
    color: #000 !important;
}

.hero-slide-left h1 {
    font-size: 54px;
    line-height: 75px;
    margin-bottom: 20px;
    color: #1E9BCC !important;
}

.hero-slide-left h2 {
    font-size: 36px;
    color: #1E9BCC !important;
}

.hero-slide-left a {
    margin-top: 40px;
}

.hero-slide-right {
    position: relative;
    /*background-image: url('img/hero-img-bg.png');*/
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}

.animate-img {
    position: relative;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 22;
}

.animate-img img:first-child {
    left: -91px;
    position: relative;
    -webkit-animation-name: movebounce;
            animation-name: movebounce;
    -webkit-animation-delay: 100ms;
            animation-delay: 100ms;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    bottom: 40px;
}

.animate-img .aimg2 {
    margin-left: 88px;
    -webkit-animation-name: movebounce;
            animation-name: movebounce;
    -webkit-animation-delay: 200ms;
            animation-delay: 200ms;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-duration: 2.5s;
            animation-duration: 2.5s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    bottom: 30px;
}


/* features section */

.icon-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
   /* background-image: url('img/featured_icon_bg.png');*/
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    width: 103px;
    height: 68px;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin: 0px auto;
    text-align: center;
    margin-bottom: 45px;
}

.single-feature-box h3 {
    margin-bottom: 10px;
    font-size: 1.4rem;
}
.single-feature-box {
    text-align: center;
}

.icon-box img {
    display: inline-block;
    margin: 0px auto;
}

.single-feature-box {
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
   -webkit-box-shadow: 8.135px 18.271px 40px 0px rgba(0, 0, 0, 0.08);
            box-shadow: 8.135px 18.271px 40px 0px rgba(0, 0, 0, 0.08);
    padding: 60px 50px 75px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
    margin-bottom: 25px;
}

.single-feature-box:hover {
    -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
            transform: translateY(-10px);
}


/* about us 
--------------------   */

.about-heading {
    margin-bottom: 20px;
}

.about-us-one-left img:first-child {
    width: 89%;
}

.about-us-one-right a {
    margin-top: 17px;
}
.fun-fact-section {
    background-color: #fff;
    -webkit-box-shadow: 8.135px 18.271px 40px 0px rgba(0, 0, 0, 0.08);
            box-shadow: 8.135px 18.271px 40px 0px rgba(0, 0, 0, 0.08);
    margin-top: 50px;
    padding: 65px 80px 21px;
    position: relative;
    height: 100%;
    overflow: hidden;
}

.single-funfact-one span {
    color: var(--heading-color);
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
    position: relative;
}

.single-funfact-one p {
    margin-top: 8px;
}

.single-service-box p, .single-service-box a {
    color:#d7d3d3 !important
}
.single-funfact-one span::before {
    position: absolute;
    content: "+";
    left: 105%;
}

.single-funfact-one p {
    color: #666;
    font-weight: 300;
    line-height: 1;
}

.single-funfact-one {
    position: relative;
    width: 50%;
    margin-bottom: 45px;
    float: left;
}
.fun-fact-section .row {
    margin-bottom: -45px;
    margin-left: 0;
    margin-right: 0;
}


/* service section
-------------------------------  */

.single-service-box {
    border-width: 1px;
    border-color: #3672bc;
    border-style: solid;
    border-radius: 5px;
    padding: 60px 47px 70px;
    margin-top: 30px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    background: transparent;
}

.service-icon {
    margin-bottom: 44px;
}

.service-icon img{
    height: 140px;
}

.single-service-box h3 {
    margin-bottom: 12px;
}

.single-service-box a {
    margin-top: 18px;
}

.single-service-box:hover {
    background: #1259b0;
    cursor: cell;
    border-color: transparent;
}

.single-service-box:hover a {
    color: var(--main-color) !important;
}


/* Gallery 
-------------------- */

.single-gallery-item {
    border-radius: 12px;
    background-color: #eee;
    height: 332px;
    z-index: 1;
    margin-top: 30px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
}
.single-gallery-item img {
    max-width: 100% !important;
}
.single-gallery-item a {
    color: #fff;
}

.gallery-over {
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    text-align: center;
    content: "";
}

.gallery-over::before {
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    text-align: center;
    content: "";
    background: var(--main-color);
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
    z-index: -1;
    border-radius: 12px;
}

.gallery-over a {
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    font-size: 50px;
    top: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
}

.single-gallery-item:hover .gallery-over::before {
            visibility: visible;
            opacity: .8;
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
}

.single-gallery-item:hover .gallery-over a {
    opacity: 1;
    visibility: visible;
}


/* appointment 
-----------------------  */

.appointment-form form input,
.appointment-form form textarea {
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 5.288px 11.876px 40px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 5.288px 11.876px 40px 0px rgba(0, 0, 0, 0.05);
    height: 68px;
    border: none;
    line-height: 68px;
    width: 100%;
    color: #666;
    font-size: 16px;
    padding-left: 30px;
    font-weight: 300;
    margin-top: 30px;
}

.appointment-form form input::-webkit-input-placeholder, .appointment-form form textarea::-webkit-input-placeholder {
    color: #666;
    text-transform: capitalize;
}

.appointment-form form input::-moz-placeholder, .appointment-form form textarea::-moz-placeholder {
    color: #666;
    text-transform: capitalize;
}

.appointment-form form input:-ms-input-placeholder, .appointment-form form textarea:-ms-input-placeholder {
    color: #666;
    text-transform: capitalize;
}

.appointment-form form input::-ms-input-placeholder, .appointment-form form textarea::-ms-input-placeholder {
    color: #666;
    text-transform: capitalize;
}

.appointment-form form input::placeholder,
.appointment-form form textarea::placeholder {
    color: #666;
    text-transform: capitalize;
}

button.submit-btn {
    background: var(--main-color);
    color: #fff;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    font-weight: 700;
    display: inline-block;
    padding: 22px 55px;
    border-radius: 3px;
    line-height: 1;
    margin-left: 15px;
    margin-top: 30px;
}

button.submit-btn:hover {
    opacity: .8;
}

.appointment-form form textarea {
    min-height: 300px;
}

.appointment-right-banner {
    position: absolute;
    right: 120px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}
@media (max-width: 1850px ) {
    .appointment-right-banner {
        display: none;
    }
}
.promo-right-banner::before {
    position: absolute;
    left: -50px;
    bottom: -25px;
    background: url('img/promo_bg.png');
    content: "";
    width: 636px;
    height: 420px;
    z-index: -1;
}

.appointment-promo-wrapper {
    overflow: hidden;
}

.appointment-promo-content h1 {
    font-size: 36px;
    line-height: 48px;
}

.appointment-promo-content a.theme-btn {
    margin-top: 30px;
    padding: 0px 37.5px;
}

.promo-right-banner {
    margin-top: 32px;
    position: relative;
    z-index: 1;
}


/* doctor team
--------------------- */

.single-doctor {
    border-radius: 10px;
    background-color: #fff;
    -webkit-box-shadow: 8.135px 18.271px 40px 0px rgba(0, 0, 0, 0.08);
            box-shadow: 8.135px 18.271px 40px 0px rgba(0, 0, 0, 0.08);
    padding: 50px 56px;
    margin-top: 30px;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}

.doctor-profile {
    border-radius: 50%;
    width: 217px;
    height: 217px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin: 0px auto;
}

.doctor-profile img {
    position: absolute;
    left: 54%;
    bottom: -5px;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 4;
}

.doctor-profile::before {
    background: var(--main-color);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -1;
    border-radius: 50%;
    top: -1px;
}

.doctor-social-icons a {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: #666666;
    color: #fff;
    font-size: 13px;
    line-height: 24px;
    border-radius: 2px;
    margin: 0px 5px;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}

.doctor-social-icons a:hover {
    background-color: var(--main-color);
}

.doctor-info {
    margin-top: 33px;
}

.doctor-info h3 {
    line-height: 1;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.doctor-social-icons {
    border-top: 2px solid #dcdcdc7a;
    padding-top: 15px;
    margin-top: 14px;
}

.single-doctor:hover {
    -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
            transform: translateY(-10px);
}

.single-doctor:hover h3 a {
    color: var(--main-color);
}


/* testimonial area */

.single-client-box {
    border-radius: 10px;
    background-color: #FFF;
    -webkit-box-shadow: 5.288px 11.876px 40px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 5.288px 11.876px 40px 0px rgba(0, 0, 0, 0.05);
    text-align: center;
    padding: 60px 58px 50px;
}

.client-image {
    position: relative;
    width: 233px;
    height: 233px;
    margin-bottom: 24px;
    overflow: hidden;
    border-radius: 50%;
    z-index: 1;
}

.client-image::before {
    background-color: #1E9BCC;
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50%
}

.client-image img {
    position: absolute;
    text-align: center;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 7;
}

.clients-review-list {
    padding-left: 30px;
    padding-right: 85px;
}

.clients-review-list i.fa-arrow-left,
.clients-review-list i.fa-arrow-right {
    position: absolute;
    bottom: -88px;
    border-radius: 5px;
    -webkit-box-shadow: 5.288px 11.876px 40px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 5.288px 11.876px 40px 0px rgba(0, 0, 0, 0.05);
    width: 51px;
    height: 52px;
    color: red;
    padding-left: 20px;
    line-height: 52px;
    -webkit-transition: .35s;
    -o-transition: .35s;
    transition: .35s;
}

.clients-review-list i.fa-arrow-right {
    left: 83px;
}
.clients-review-list i:hover {
    color: #fff;
    cursor: pointer;
    background-color: var(--main-color);
}


/* Home Page One Blog */

.single-blog-card {
    border-radius: 10px;
    background-color: #fff;
    -webkit-box-shadow: 5.288px 11.876px 40px 0px rgba(0, 0, 0, 0.05);
            box-shadow: 5.288px 11.876px 40px 0px rgba(0, 0, 0, 0.05);
    display: inline-block;
    margin-top: 40px;
    overflow: hidden;
}
.single-blog-card img {
    width: 100%;
    height: 100%;
}
.featured-thumb {
    background-color: #eee;
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0px 0px;
    background-size: cover;
    background-repeat: no-repeat;

}

.single-blog-card .card-content h3 {
    font-weight: 500 !important;
    border-bottom: 2px solid #dcdcdc7d;
    padding-bottom: 25px;
    margin-bottom: 10px;
}

.single-blog-card .card-content:hover h3 {
    color: #000;
}
.single-blog-card .card-content {
    padding: 40px;
}

.single-blog-card .card-content .card-meta span {
    display: inline;
    font-weight: 300;
    font-size: 16px;
}

.single-blog-card .card-content .card-meta .col-6 {
    padding: 0;
    margin: 0;
}

.single-blog-card .card-content .card-meta {
    display: flex;
}

.single-blog-card:hover .featured-thumb {
    background: var(--main-color);
}

.single-blog-card:hover {
    background: #F5E399;
    color: #000;

}

.single-blog-card:hover h3 {
    color: #000;

}


/* Dentist Details  */

.dentist-profile-img {
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 8.135px 18.271px 40px 0px rgba(0, 0, 0, 0.08);
            box-shadow: 8.135px 18.271px 40px 0px rgba(0, 0, 0, 0.08);
    width: 100%;
    height: 362px;
    overflow: hidden;
}

.dentist-profile-img img {
    max-width: inherit;
    margin: 0px auto;
}

.single-dentist-details h2 {
    display: inline-block;
    margin-right: 20px;
}

.single-dentist-details span {
    margin-bottom: 26px;
    display: inline-block;
}

.single-dentist-details p {
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 13px;
}

.dentist-profile-details {
    text-align: center;
}

.dentist-profile-details span {
    display: block;
}

.achievement-img-banner {
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.08);
            box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.08);
    min-height: 405px;
    overflow: hidden;
}


/* footer 
--------------------------- */

.footer-widgets-wrapper {
    padding: 100px 0px 90px;
}

.single-footer-widget h2 {
    line-height: 1;
    font-size: 30px;
}
.single-footer-widget a {
    font-weight: 400;
    display: block;
    line-height: 34px;
    color: #000 !important;
}

.widegts-content p, .widegts-content li {
    color: #000 !important;
}

.footer-copyright-wrapper {
    background: #1259b0;
    padding: 20px 0px;
}

.footer-copyright-wrapper p,
.footer-copyright-wrapper a {
    color: #fff;
}

.single-footer-widget a:hover,
.footer-copyright-wrapper a:hover {
    color: #1E9BCC !important;
}

.f-widget-title {
    margin-bottom: 30px;
}


/* inner page banner 
-------------------------------*/

.banner-animate {
    position: relative;
    z-index: 2;
}

.banner-animate a1 {
    position: relative;
}

.bannerimg {
    position: absolute;
    bottom: 0;
}

.bannerimg.a2 {
    right: 35%;
    bottom: 50px;
    -webkit-animation: movebounce 6s infinite linear;
            animation: movebounce 6s infinite linear;
}

.bannerimg.a3 {
    left: 28%;
    bottom: -20px;
    -webkit-animation: movebounce 4s infinite linear;
            animation: movebounce 4s infinite linear;
}

.bannerimg.a4 {
    bottom: 100px;
    right: 24%;
}

.bannerimg.a5 {
    top: 32px;
    right: 26%;
    z-index: -1;
}

.shape.breadcrumb1 {
    right: 52%;
    top: 40%;
}

.shape.breadcrumb2 {
    top: 50%;
    right: 20%;
}

.shape.breadcrumb3 {
    left: 90px;
    top: 20%;
}

.shape.breadcrumb4 {
    right: 50px;
    top: 50%;
}

.shape.breadcrumb5 {
    left: 40%;
    top: 30%;
}

.shape.breadcrumb7 {
    left: 160px;
    bottom: 50px;
}

.shape.breadcrumb8 {
    left: 40px;
    top: 50%;
}

.page-banner-breadcrumb h1 {
    font-size: 46px;
    line-height: 1;
    text-transform: capitalize;
    color: #315D9C !important;
}

.breadcrumb-nav a {
    font-weight: 400;
    text-transform: capitalize;
    margin-right: 15px;
    position: relative;
}

.breadcrumb-nav a::before {
    position: absolute;
    content: "";
    right: -12px;
    width: 4px;
    height: 2px;
    background: #fff;
    top: 50%;
}

.breadcrumb-nav a:last-child::before {
    display: none;
    opacity: 0;
}

.breadcrumb-wrap,
.page-banner {
    padding: 30px;
    padding-top: 160px;
    position: relative;
}


/* contact page 
------------------------------- */

.single-contact-info {
    min-height: 171px;
    background: #fff;
    -webkit-box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.06);
            box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.06);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
    padding: 0px 35px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

.single-contact-info:hover {
    -webkit-transform: translateY(-10px);
        -ms-transform: translateY(-10px);
            transform: translateY(-10px);
}

.single-contact-info .c-icon {
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 70px;
    background: #FFE7EA;
    display: block;
    border-radius: 50%;
    color: var(--main-color);
    font-size: 26px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    margin-right: 20px;
}

.single-contact-info.icon2 .c-icon {
    color: #16D88A;
    background: #DCF9EE;
}

.single-contact-info.icon3 .c-icon {
    color: #352E94;
    background: #E1E0EF;
}

.single-contact-info.icon2:hover .c-icon {
    background: #16D88A;
    color: #fff;
}

.single-contact-info.icon3:hover .c-icon {
    background: #352E94;
    color: #fff;
}

.single-contact-info.icon1:hover .c-icon {
    background: var(--main-color);
    color: #fff;
}

.c-info {
    text-align: left;
}

.c-info p {
    margin: 0;
    font-weight: 300;
    line-height: 26px;
    color: #999999;
}

.c-info h4 {
    text-transform: capitalize;
}

.contact-form-wraper {
    margin-top: 60px;
}

.contact-form-wraper h1 {
    font-size: 60px;
    line-height: 1;
    margin-bottom: 50px;
    margin-top: 20px;
}

.contact-form-wraper form input,
.contact-form-wraper form textarea {
    width: 100%;
    height: 71px;
    background: #fff;
    -webkit-box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.06);
            box-shadow: 0px 6px 26px rgba(0, 0, 0, 0.06);
    border: none;
    padding-left: 30px;
    text-transform: capitalize;
    margin-bottom: 29px;
    color: #666666;
}

.contact-form-wraper form textarea {
    height: 300px;
    padding-top: 30px;
}

button.contact-submit-btn {
    height: 62px;
    background: var(--main-color);
    color: #fff;
    text-transform: capitalize;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    margin-left: 15px;
    display: inline-block;
    padding: 0px 45px;
    margin-top: 30px;
    font-weight: 500;
}



/* blog deatils - page
----------------------------- */

.featured-thubnail {
    margin-bottom: 60px;
}

.post-meta {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 20px;
    color: #666;
}

.post-meta strong {
    font-weight: 700;
    padding-right: 5px;
}

.single-blog-details p {
    color: #666;
    font-weight: 300;
}

.single-blog-details blockquote {
    font-size: 18px;
    font-weight: 500;
    color: #666;
    font-style: italic;
}

.single-blog-details blockquote span {
    display: block;
    font-style: normal;
    font-weight: 400;
}

.post-tags-cate-section a {
    color: #818181;
}

.featured-thubnail {
    margin-bottom: 60px;
}

.post-meta {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 20px;
    color: #666;
}

.post-meta strong {
    font-weight: 700;
    padding-right: 5px;
}

.single-blog-details p {
    color: #666;
    font-weight: 300;
}

.single-blog-details blockquote {
    font-size: 18px;
    font-weight: 500;
    color: #666;
    font-style: italic;
}

.single-blog-details blockquote span {
    display: block;
    font-style: normal;
    font-weight: 400;
}

.single-blog-details p:last-child {}

.post-tags-cate-section a {
    color: #818181;
}

.author-bio-section {
    margin: 60px 0px;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    padding: 45px 0px;
}

.author-bio-section img {
    float: left;
    margin-right: 30px;
}

.author-bio-section h4 a {
    font-size: 18px;
    color: #3B3B3B;
    margin: 0;
    line-height: 1;
    font-weight: 700;
}

.author-bio-section h4 {
    margin: 0;
}

.related-blog-posts-section h1 {
    line-height: 1;
    margin-bottom: 24px;
}

.comment-template-section {
    margin: 51px 0px;
}

.single-comment img {
    float: left;
    margin-right: 30px;
}

.commentor-info h5 {
    margin: 0;
    display: inline-block;
    color: #3B3B3B;
}

.commentor-info span {
    font-weight: 300;
    font-size: 12px;
    margin-left: 8px;
    color: #666;
}

.comment-text p {
    color: #818181;
    margin: 0;
}

.btn-replay {
    color: #3B3B3B;
    display: inline-block;
    float: left;
}

.comment-text {
    overflow: hidden;
}

.single-comment {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
}

.single-comment.replay {
    margin-left: 70px;
}

form .radio-checked input {
    width: 15px;
    height: 15px;
    margin: 0;
    display: inline-block;
}

form .radio-checked span {
    font-size: 18px;
    line-height: 1;
    color: #666;
    font-weight: 300;
    padding-left: 5px;
}

.comment-template-section h3 {
    margin-bottom: 20px;
}

.contact-submit-btn:hover {
    background: var(--main-color);
}

.btn-replay:hover,
.post-tags-cate-section a:hover {
    color: var(--main-color);
}

.post-tags-cate-section span {
    font-weight: 500;
    color: #3B3B3B;
    margin-right: 5px;
}

.post-tags-cate-section a {
    position: relative;
    padding-right: 12px;
}

.post-tags-cate-section a::before {
    position: absolute;
    width: 10px;
    height: auto;
    content: ",";
    right: 0;
    bottom: -5px;
}

.post-tags-cate-section a:last-child::before {
    display: none;
}

.search-form input {
    border: none;
    border-bottom: 1px solid #E1E1E1;
    color: #666;
    position: relative;
    width: 100%;
    font-size: 16px;
    padding-left: 10px;
}

.search-form button {
    position: absolute;
    right: 0;
    background: transparent;
    color: #999999;
}

.search-form input::-webkit-input-placeholder {
    color: #C5C5C5;
}

.search-form input::-moz-placeholder {
    color: #C5C5C5;
}

.search-form input:-ms-input-placeholder {
    color: #C5C5C5;
}

.search-form input::-ms-input-placeholder {
    color: #C5C5C5;
}

.search-form input::placeholder {
    color: #C5C5C5;
}

.search-form button:hover {
    color: #333;
}

.single-sidebar-widgets {
    margin-bottom: 60px;
}

.single-sidebar-widgets h3 {
    margin-top: -8px;
    text-transform: capitalize;
    color: #3B3B3B;
    font-weight: 900;
    font-size: 24px;
    margin-bottom: 16px;
}

.single-sidebar-widgets a {
    color: #222;
    font-family: 'Muli', sans-serif;
    font-size: 24px;
    font-weight: 400;
    display: block;
    line-height: 34px;
}

.single-populer-post span {
    font-size: 12px;
}

.single-populer-post {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.single-sidebar-widgets a:hover {
    color: var(--main-color);
}

.single-populer-post:last-child {
    border: none;
    margin-bottom: 0px;
}

.single-populer-post p {
    margin-top: 10px;
    margin-bottom: 5px;
}

.tags-cloud a {
    font-size: 16px;
    display: inline-block;
    color: #666;
    margin-right: 18px;
    line-height: 26px;
}

.single-sidebar-widgets .social-links a:hover {
    color: #fff;
    background: var(--main-color);
    background: -webkit-gradient(linear, left top, left bottom, from(var(--main-color)), to(var(--main-color)));
    background: -o-linear-gradient(top, var(--main-color), var(--main-color));
    background: linear-gradient(to bottom, var(--main-color), var(--main-color));
}

.single-sidebar-widgets .social-links a {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}

.single-sidebar-widgets .social-links {
    margin-top: 0px;
}

.single-sidebar-widgets:last-child {
    margin-bottom: 0px;
}

.blog-page-nav ul li,
.blog-pages-link ul li {
    display: inline-block;
    margin-right: 15px;
}

.blog-page-nav ul li a,
.blog-pages-link ul li a {
    border-style: solid;
    border-width: 1px;
    border-color: var(--main-color);
    border-radius: 50%;
    width: 47px;
    height: 47px;
    display: inline-block;
    line-height: 47px;
    text-align: center;
    color: var(--main-color);
    background: #fff;
}

.blog-page-nav ul li a:hover,
.blog-pages-link ul li a:hover {
    background: var(--main-color);
    color: #fff;
}

.sidebar-wrapper {
    padding-left: 40px  !important;
}
.single-sidebar-widgets .social-links a {
    display: inline-block;
    background: var(--main-color);
    font-size: 16px;
    line-height: 40px;
    color: #fff;
    transition: .4s;
    width: 40px;
    text-align: center;
    margin-right: 5px;
    border-radius: 4px;
}

/* 
---------------------------------------
homepage two 
--------------------------------------- */

.top-header {
    background: #FFF3F4;
    font-family: var(--heading-family-two);
    font-size: 16px;
    padding: 22px 0px;
    line-height: 26px;
}

.top-header a {
    color: #222222;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.top-header a:hover {
    color: var(--main-color-two);
}

.about-welcome-message h1 {
    font-weight: 600;
    font-size: 60px;
    line-height: 70px;
    position: relative;
    margin-bottom: 45px;
}

.about-welcome-message h1::before {
    position: absolute;
    width: 80px;
    height: 2px;
    background: var(--main-color-two);
    content: "";
    left: 0;
    bottom: 0;
}

.about-welcome-message blockquote {
    font-weight: 700;
    font-size: 18px;
    font-style: italic;
    margin-top: 35px;
    margin-bottom: 35px;
}

.about-welcome-message a {
    color: #1E266D;
    font-family: var(--heading-family-two);
    margin-top: 35px;
    display: inline-block;
}

.about-welcome-message a i {
    color: var(--main-color-two);
    padding-left: 5px;
}

.about-welcome-message li {
    position: relative;
    padding-left: 15px;
    margin-bottom: 7px;
}

.about-welcome-message li::before {
    position: absolute;
    left: 0;
    width: 5px;
    height: 5px;
    content: "";
    background: var(--main-color-two);
    border-radius: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}

.about-two-video {
    height: 612px;
    background-color: var(--main-color-two);
    text-align: center;
    position: relative;
    z-index: 11;
    margin-top: 36px;
}

.about-us-img {
    width: 100%;
    position: absolute;
    left: 35px;
    top: -35px;
    background-color: #eee;
    content: "";
    height: 100%;
    z-index: 999;
}

.about-two-video a {
    width: 100px;
    height: 100px;
    background: #fff;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    left: 50%;
    z-index: 999;
}

.about-two-video a i {
    font-size: 27px;
    line-height: 100px;
    color: var(--main-color-two);
}

.single-slide-item .hero-slide-left h2 {
    font-size: 30px;
    font-weight: 300;
}

.single-slide-item .hero-slide-left h1 {
    font-size: 67px;
    line-height: 80px;
    color: var(--second-color-two);
}

.single-slide-item .hero-slide-left p {
    font-size: 16px;
    line-height: 30px;
}

.single-dental-service {
    border: 1px solid #e5eff1;
    padding: 48px;
    padding-top: 46px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.service-icon {
    position: relative;
    display: inline-block;
}

span.back-big-text {
    font-family: var(--heading-family-two);
    font-size: 150px;
    position: absolute;
    font-weight: 600;
    color: #F9F9F9;
    top: -24px;
    text-transform: capitalize;
}

.about-welcome-message {
    position: relative;
    z-index: 1;
}

.second-section-title {
    position: relative;
    z-index: 1;
    margin-bottom: 40px;
    margin-top: -10px;
}

.second-section-title span.back-big-text {
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
}

.dentist-service-boxs .single-dental-service {
    margin-top: 30px;
}

.section-bg-two span.back-big-text {
    color: #2A3277 !important;
}

.second-section-title h1 {
    font-size: 60px;
    font-weight: 600;
    line-height: 70px;
    position: relative;
    padding-bottom: 18px;
}

.second-section-title h1::before {
    width: 80px;
    height: 2px;
    left: 50%;
    bottom: 0;
    content: "";
    background: var(--main-color-two);
    position: absolute;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
}

.footer-two-widgets-wrap {
    background: #FFF3F4;
    padding: 90px 0px;
}

.single-foo-widgets-two h3 {
    font-size: 30px;
    line-height: 46px;
    font-weight: 600;
    position: relative;
    padding-bottom: 5px;
}

.single-foo-widgets-two h3::before {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 60px;
    height: 2px;
    background: var(--main-color-two);
}

.single-foo-widgets-two .foo-wid-menu li a {
    font-size: 18px;
    line-height: 32px;
    color: #555;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    display: inline-block;
    position: relative;
}

.single-foo-widgets-two .foo-wid-menu {
    margin-top: 25px;
}

.single-foo-widgets-two .foo-wid-menu li a:hover {
    color: var(--main-color-two);
    padding-left: 10px;
}

.single-foo-widgets-two .foo-wid-menu li a::before {
    content: "";
    position: absolute;
    right: -25px;
    font-family: "Font Awesome 5 Pro";
    visibility: hidden;
    opacity: 0;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.single-foo-widgets-two .foo-wid-menu li a:hover::before {
    opacity: 1;
    visibility: visible;
}

.blog-featured-img {
    height: 279px;
    background-color: #eee;
    position: relative;
    background-size: cover;
}

.single-blog-item-two {
    background-color: #F6F6F6;
    max-width: 350px;
    overflow: hidden;
    display: inline-block;
}

.single-blog-item-two>a {
    font-family: Poppins;
    color: #222222;
    margin-bottom: 47px;
    display: inline-block;
    margin-top: 23px;
}

.single-blog-item-two h3 a {
    font-size: 24px;
    line-height: 30px;
    font-family: Poppins;
    font-weight: 500;
}

.blog-two-content {
    padding: 0px 42px;
    padding-bottom: 45px;
}

.blog-two-content h3,
.blog-two-content h3 a {
    font-size: 24px;
    line-height: 30px;
    font-family: var(--heading-family-two);
    font-weight: 500;
    margin-bottom: 27px;
    display: block;
    transition: .2s;
}
.blog-two-content h3:hover, .blog-two-content a:hover {
    color: var(--main-color);
}
.blog-two-content a {
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    display: inline-block;
    font-family: Poppins;
    color: #222222;
    margin-top: 22px;
}

.single-blog-item-two .post-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
}

.single-blog-item-two .post-meta span {
    margin-right: 35px;
    line-height: 1;
}

.single-blog-item-two h3 {
    margin-bottom: 27px;
}

.specialist-img {
    height: 329px;
    background-color: #eee;
    position: relative;
}

.specialist-info {
    padding: 45px;
    background: #F9F9F9;
    padding-top: 40px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.specialist-info h3 {
    font-size: 24px;
    line-height: 35px;
    font-weight: 500;
}

.single-specialist-doctor a {
    display: inline-block;
    margin-top: 20px;
}

.single-specialist-doctor a {
    color: #222222;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.specialist-info span {
    font-size: 18px;
    color: #666666;
    line-height: 1;
}

.single-specialist-doctor {
    margin-top: 30px;
}

.single-specialist-doctor:hover .specialist-info {
    background: #1E266D;
}

.single-specialist-doctor:hover a,
.single-specialist-doctor:hover h3,
.single-specialist-doctor:hover span {
    color: #fff;
}

.banner-promo-block {
    background-repeat: no-repeat;
    background-position: right;
}
@media (max-width: 1191px) {
    .banner-promo-block {
        -webkit-background-size: cover;
        background-size: cover;
    }

    .banner-promo-block::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background: var(--main-color);
        opacity: .8;
    }
}

.banner-promo-content span {
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    margin-top: 35px;
    display: inline-block;
}

.banner-promo-content h1 {
    font-size: 60px;
    line-height: 70px;
    color: #fff !important;
}


/* Gallery - Two */

.single-showcase-item {
    height: 356px;
    position: relative;
    background-color: #eee;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 30px;
}

.showcase-over {
    position: absolute;
    width: 86%;
    height: 86%;
    top: 7%;
    left: 7%;
    text-align: center;
    z-index: 1;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
}

.showcase-over a {
    position: absolute;
    left: 50%;
    top: 50%;
    color: #fff;
    margin-top: -20.5px;
    margin-left: -20.5px;
}

.showcase-over a i {
    font-size: 41px;
    line-height: 1;
}

.showcase-over::before {
    background: #7278E8;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    content: "";
    opacity: .5;
}

.single-showcase-item:hover .showcase-over {
            opacity: 1;
            visibility: visible;
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
            -moz-transform: scale(1);
            -o-transform: scale(1);
}

.single-dental-service h3 {
    font-weight: 500 !important;
    line-height: 35px;
    margin-bottom: 30px !important;
}


/* about page */

.best-service-item-box h3 {
    margin-bottom: 10px;
    font-size: 24px;
}
.best-service-item-box p{
    font-size: 18px;
}
.service-box-icon img {
    width: 60px;
}

.service-box-icon {
    margin-bottom: 34px;
}
.service-box-icon1 img {
    width: 160px;
}

.best-service-item-box {
    padding: 70px 48px 63px;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    border: 1px solid #c4eeff;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 4px;
    margin-top: 30px;
}

.best-service-item-box:hover {
    background: #1e9bcc;
    border-color: #1e9bcc;
    -webkit-box-shadow: var(--box-shadow);
            box-shadow: var(--box-shadow)
}

@media (max-width: 991px) {
    .best-service-item-box {
        padding: 60px 30px 53px;
    }
}


/* promo section */

.promo-content-section p {
    font-weight: 300;
}

.promo-text h2 {
    margin-bottom: 23px;
}

div.promo-text p {
    margin-bottom: 15px !important;
}

div.promo-text p:last-child {
    margin-bottom: 0px !important;
}


/* Our Achievement  -  Section */

.single-am-funfact {
    background-image: url("img/funfact_bg.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    padding-top: 70px;
    padding-bottom: 30px;
    margin-top: 30px;
}

.single-am-funfact span {
    font-size: 24px;
    font-weight: 700;
    position: relative;
    line-height: 30px;
    display: inline-block;
    padding-right: 16px;
}

.single-am-funfact span::before {
    position: absolute;
    right: 0;
    content: "+";
    font-weight: 700;
}

.our-achievement-wrap.section-padding {
    padding-top: 90px;
}


/* Safety First */

.video-bg-img {
    background-color: #eee;
    text-align: center;
    height: 355px;
    position: relative;
    background-position: center;
    background-size: cover;
    margin-bottom: 35px;
}

.video-play-button {
    height: 57px;
    width: 57px;
    background: #FC5185;
    position: absolute;
    left: 50%;
    top: 50%;
    line-height: 57px;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    border-radius: 50%;
    font-weight: 700;
    color: #fff;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}

.single-video-item {
    text-align: center;
    margin-top: 30px;
}

.single-video-item h5 {
    color: #818181;
}

.video-play-button:hover {
    -webkit-box-shadow: var(--box-shadow-two);
            box-shadow: var(--box-shadow-two);
    color: #fff;
}

.section-text-wrap a {
    margin-top: 40px;
}


/* footer two page */

.footer-bottom-copyright p {
    font-size: 16px;
    line-height: 20px;
    color: #fff;
}

.footer-bottom-copyright p a {
    color: #fff;
}

.footer-bottom-social-wrap a {
    font-size: 14px;
    line-height: 24px;
    color: var(--second-color-two);
    display: inline-block;
    width: 24px;
    text-align: center;
    font-weight: 400;
    border-radius: 3px;
    background: #fff;
}

.footer-bottom-copyright {
    padding: 16px 0px;
    background: var(--second-color-two);
}

.footer-bottom-copyright .row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}


/* subscribe section - home two */

.subscribe-form-wrap form input {
    height: 52px;
    width: 255px;
    border: none;
    padding-left: 15px;
    margin-right: 10px;
}

.subscribe-form-wrap form button {
    height: 52px;
    display: inline-block;
    line-height: 52px;
    border: none;
    background: #FF8A8B;
    width: 160px;
    color: #fff;
    margin-left: -10px;
}

.subscribe-form-wrap form {
    position: relative;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 35px;
    font-family: Poppins;
    font-size: 16px;
}

.subscribe-form-wrap h1 {
    font-size: 60px;
    line-height: 70px;
    margin-top: -10px !important;
}

.single-dental-service .service-icon::before,
.single-dental-service .service-icon::after {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #FF8A8B;
    content: "";
    left: -10px;
}

.single-dental-service .service-icon::after {
    left: auto;
    top: 30px;
}

.single-dental-service::before {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #EEEEEE;
    content: "";
    left: 120px;
    top: 60px;
    z-index: 9;
}

.single-dental-service a {
    margin-top: 10px;
    display: inline-block;
    font-size: 16px;
}

.single-dental-service:hover a i {
    padding-left: 5px;
}

.single-dental-service a i {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}

.single-dental-service:hover {
    border-color: var(--main-color);
}

/* home 2 slider */
.slide-item .hero-slide-content h3 {
    font-size: 30px;
    font-weight: 300;
}
.slide-item .hero-slide-content h1 {
    font-size: 67px;
    line-height: 80px;
    margin-bottom: 30px;
}

.slide-item .hero-slide-content p {
    font-size: 16px;
    line-height: 30px;
}

.slide-item .hero-slide-content a {
    font-size: 16px;
    font-weight: 500;
    font-family: Poppins;
    margin-top: 50px;
}

.slide-item {
    min-height: 900px;
    align-items: center;
    display: flex;
    background-color: #FF8A8B;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.hero-slide-content .theme-btn.btn_black {
    color: #000;
    border-color: #999;
}
.hero-slide-content .theme-btn.btn_black:hover {
    color: #1E9BCC;
    border-color: var(--main-color);
    background: var(--main-color);
}
.slide-item::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: #000;
    opacity: 0.05;
}


/* subscribe  - home two */

.subscribe-doctor-img img {
    margin-left: 70px;
}

.subscribe-news-block .row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.single-patients-img {
    text-align: center;
    background: #FF8A8B;
    -webkit-box-shadow: 10px 15px 40px rgba(0, 0, 0, 0.06);
            box-shadow: 10px 15px 40px rgba(0, 0, 0, 0.06);
    padding: 35px 21px;
    padding-bottom: 0;
    position: relative;
    z-index: 99;
}

.patients-name-info h3 {
    line-height: 30px;
}

.patients-name-info span {
    font-size: 16px;
    line-height: 30px;
}

.patients-review-items {
    min-height: 360px;
    background: #fff;
    -webkit-box-shadow: 10px 15px 40px rgba(0, 0, 0, 0.06);
            box-shadow: 10px 15px 40px rgba(0, 0, 0, 0.06);
    padding: 55px;
    position: relative;
}

.patients-name-info {
    margin-bottom: 29px;
}

.patients-review-item-box p {
    font-style: italic;
    font-size: 18px;
}

.patients-review-item-box {
    margin-bottom: 50px;
    overflow: auto;
    padding-bottom: 30px;
}

.patients-review-items .slick-arrow {
    position: absolute;
    bottom: 55px;
    width: 50px;
    height: 50px;
    background: #666666;
    text-align: center;
    line-height: 50px;
    color: #fff;
    z-index: 1;
}

.patients-review-items .fa-arrow-right.slick-arrow {
    margin-left: 75px;
}

.dentist-social-link a {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: #666666;
    color: #fff;
    font-size: 13px;
    line-height: 24px;
    border-radius: 2px;
    margin: 0px 5px;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}

.dentist-social-link a:hover {
    background-color: var(--main-color);
}

.dentist-social-link {
    margin-top: 5px;
}
.dentist-award-membership li {
    position: relative;
    display: inline-block;
    padding-left: 16px;
    margin-right: 25px;
    margin-top: 14px;
}

.dentist-award-membership li::before {
    position: absolute;
    left: 0;
    border-radius: 50%;
    background-color: var(--main-color);
    width: 9px;
    height: 9px;
    content: "";
    top: 11px;
}

.dentist-award-membership h3 {
    margin-bottom: 7px;
}

.single-dentist-details p {text-align: justify;}
.single-dentist-details h2 span {
    font-size: 20px;
    color: #666666;
}
.profile-img {
    border-radius: 10px;
    background-color: #fff;
    -webkit-box-shadow: 8.135px 18.271px 40px 0px rgba(0, 0, 0, 0.08);
            box-shadow: 8.135px 18.271px 40px 0px rgba(0, 0, 0, 0.08);
    height: 362px;
    overflow: hidden;
}
span.line {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 1px;
    height: 70%;
    background: #e6e6e6;
    content: "";
}
.single-funfact-one.sp-fun {padding-left: 60px;}

.hero-shape {
    overflow: hidden;
}
.hero-slider-wrapper {
    overflow-x: hidden;
}
.our-specialist-list {
    position: relative;
}

.our-specialist-list .slick-arrow {
    width: 50.21px;
    height: 50.21px;
    background: #FFF3F4;
    line-height: 50.21px;
    text-align: center;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    left: -20%;
    color: var(--second-color-two);
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    transition: all .4s;
}

.our-specialist-list .fa-arrow-right.slick-arrow {
    left: auto;
    right: -20%;
}
.our-specialist-list .slick-arrow:hover {
    background: var(--second-color-two);
    color: #fff;
}


@media (max-width: 1191px) {
    span.back-big-text {
        font-size: 60px;
    }
    .second-section-title h1 {
        font-size: 40px;
        line-height: 50px;
        padding-bottom: 12px;
    }
    .our-specialist-list .slick-arrow {
        left: 0px;
        z-index: 1;
    }
    .our-specialist-list .fa-arrow-right.slick-arrow {
        right: 0;
    }
    .specialist-img img {
        width: 100%;
    }
} 


.mobile-menu {
    z-index: 999;
    position: relative;
    background-color: #ffffff;
}
.mobile-menu ul li a {
    display: block;
    text-transform: capitalize;
    color: #000;
}
.navbar {
    position: relative;
    float: right;
    text-align: right;
    display: block;
}
.navbar-light .navbar-toggler {
  color: #000 !important;
  border-color: #000 !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}
.navbar-light .navbar-toggler span {
  padding: 2.5px;
}
.sticky .mobile-menu {
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 111;
  box-shadow: 4px 5px 15px #eee;
  margin: 0px auto;
}
.react-fancybox .box {
    z-index: 9999999999999;
}
.breadcrumb-nav a {
    color: #fff;
}
.dentist-award-membership {
    margin-top: 40px;
}

@media (max-width: 1191px) {
    .our-achievement-wrap.section-bg.text-white.section-padding.mb-120 {
        margin-bottom: 80px;
    }
}

.vFooterBg{
    background: #c4eeff;
}
.vBlackColr{
    color: #000 !important;
}
.vLogoFooter img{
    width: 250px;
    margin: 0 0 15px 0;
}
.vFooterBottom{
    display: flex;
    justify-content: space-between;
}
.vDocMeet h3{
    margin: 15px 0 0 0;
    padding: 0;
}
.vDocMeet p{
    margin: 0;
    padding: 0;
}
.vAwards{
    margin: 0;
    padding: 0;
}
.vAwards li{
    display: inline-block;
    margin: 0 20px 20px 0;
}
.vAwards li img{
    width: 130px;
}

.vSleepDisorderBlock{
    /*background: #315D9C;*/
    background: #1E9BCC;
}

.vSleepDisorderBlock h2{
    text-align: center;
    color: #FFF !important;
    margin: 0 0 30px 0;
}

.sleepdisorder{
    color: #FFF;
    text-align: center;
    border: 1px solid #CFEEFF;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    -webkit-transition: .5s;
    transition: .5s;
    -webkit-flex: 1; /* Safari */
	-ms-flex: 1; /* IE 10 */
	flex: 1; /* Standard syntax */
    min-height: 400px;
}

.sleepdisorder img{
    display: inline-block !important;
    height:150px;
}
.sleepdisorder h3{
    margin: 15px 0 0 0;
    color: #FFF !important;
}
.vSleepOrderList{
    display: flex !important;
    min-height: 300px;
}
.sleepdisorder p{
    margin: 0;
    padding: 0;
    color: #FFF !important;
}
.vAwardsImg{
    margin-top: 25px;
    padding: 0 10px;
    font-weight: 500;
}
.menu-style-one ul li span{
    padding-left: 40px;
    font-weight: 500;
    color: #315D9C;
    font-size: 18px;
}
.vDesktop{
    display: block;
}
.vMob{
    display: none;
}
.socialMedia i{
    padding: 10px 10px 0 0;
    font-size: 24px;
    color: #1e9bcc;
}
.socialMedia a{
    display: inline-block;
}
.yellow-btn{
    background: #F5E399;
    border: 1px solid #F5E399;
    color: #000;
}
button.blue-btn{
    background: #1E9BCC;
}
.videoSpace{
    padding: 0 20px;
}
.vVedioBlock{
    padding-bottom: 60px;
}
.vVedioBlock h1{
    margin-bottom: 30px;
}
.inner-section-bg{
    background: url('../assets/img/whatsinaname/innerpagebanners/Aboutbanner.jpg') no-repeat !important;
}
.inner-section-bg1{
    background-size: cover !important;
}
.vAboutDoc h2{
    margin: 0;
    padding: 0;
}
.vHomeLink{
    color: #1e9bcc !important;
}
.vPageLink{
    color: #315D9C !important;
}
.vTreatmentPcage ul{
    margin: 0 0 15px 15px !important;
    padding: 0;
}
.vTreatmentPcage ul li{
    list-style: disc;
}
.vBlue-btn{
    background: #315D9C;
    border: 1px solid #315D9C;
}
.vPB30{
    padding-bottom: 60px;
}
.vTreatSubText{
    color: #1E9BCC;
    font-size: 22px;
}
.vDeskWidth{
    width: 80%;
}
.vFloatRight{
    float: right;
}
.card{
    border: none !important;
}
.accordion>.card>.card-header{
    background: #c4eeff;
}
.accordion>.card {
    margin-bottom: 10px;
}
.vOurInt{
    background: #c4eeff;
}
.vOurFactHeads{
    display: flex;
    justify-content: space-between;
}
.vOurImg img{
    width: 150px;
}
.vOurSpeciFeatre{
    margin: 30px 0 20px 0;
}
.vOurSpeciFeatre ul{
    margin: 0 0 0 20px !important;
    padding: 0;
}
.vOurSpeciFeatre ul li{
    list-style: disc;
}

.row.equal-cols {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  
  .row.equal-cols:before,
  .row.equal-cols:after {
    display: block;
  }
  
  .row.equal-cols > [class*='col-'] {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  
  .row.equal-cols > [class*='col-'] > * {
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto; 
  }

  .vBlue315{
    background: #315D9C !important;
  }

  .accordion>.card>.card-header:after {
    font-family: 'Glyphicons Halflings';
    content: "\002B";
    /*content: '\2212';*/
    float: right;
    color: grey;
}
.accordion>.card>.card-header:after button.btn.btn-link:not(.collapsed){
    font-family: 'Glyphicons Halflings';
    content: '\2212';
    float: right;
    color: grey;
}
.bannerimg.pa4 {
    bottom: 100px;
    right: 0;
}
.bannerimg.pa3 {
    left: -2%;
    bottom: 90px;
    animation: movebounce 4s infinite linear;
}
.bannerimg.ta5 {
    top: 0;
    right: 0;
    z-index: -1;
}
.bannerimg.sa3 {
    left: 44%;
    bottom: -20px;
    animation: movebounce 4s infinite linear;
}
.bannerimg.sa4 {
    bottom: 5px;
    right: 24%;
    width: 50px;
}
.bannerimg.aa5 {
    top: -30px;
    right: 13%;
    z-index: -1;
    width: 82%;
}
.bannerimg.aa2 {
    right: 35%;
    top: 46px;
    width: 35px;
    animation: movebounce 6s infinite linear;
}
.bannerimg.aa4 {
    top: -14px;
    right: 16%;
    width: 35px;
}
.bannerimg.nea2 {
    right: 25%;
    bottom: 18px;
    animation: movebounce 6s infinite linear;
}
.bannerimg.nea3 {
    left: 20%;
    bottom: 20px;
    width: 80px;
    animation: movebounce 4s infinite linear;
}
.bannerimg.nea4 {
    bottom: 30px;
    right: 17%;
    width: 30px;
}
.bannerimg.ba2 {
    right: 39%;
    bottom: 24px;
    width: 80px;
    animation: movebounce 6s infinite linear;
}
.bannerimg.ba4 {
    bottom: 10px;
    right: -30px;
    width: 80px;
}
.vBreatheForm{
    height: 450px;
    margin-top: 50px;
}
.single-gallery-item1{
    height: auto;
}
.vTakeTourHead{
    color: #1e9bcc;
}
.vTour360Btn{
    margin-top: 20px;
}
.vTour360Btn a{
    background: #1e9bcc;
    border-radius: 10px;
    color: #FFF;
    padding: 10px 15px;
}
.vTour360Btn a:hover{
    color:#FFF;
}
.vVirtualTourContent{
    margin: 40px 0 0 0;
}
.vTourIconBlock p{
    line-height: 1.6;
}
.vVirtalSection{
    padding-bottom: 40px;
}
.vMobAdjest{
    text-align: right !important;
    margin-right: 30px !important;
}
.mobile-nav{
    padding: 0 !important;
}
.vMenuBar{
    padding: 0 !important;
}
.footerAddress {
    display: flex;
    justify-content: space-between;
}
.branchNames{
    color: #000 !important;
    font-size: 17px;
}